var ismobile = '23';
var $window;
var $navItems;
// var fadeableContent;
// var fadeableFooter;
// var menueMaxScrollHoehe = 110;
// var teaserSliderScrollFaktor = 2.5;

var md;

var intersectionObserver;

var loaderOverridden = false;

$(document).ready(function () {
    md = new MobileDetect(window.navigator.userAgent);
    $window = $(window);
    $navItems = $('.nav-link-container');

    onepager.variables.callback = function () {
        $navItems.removeClass('nav-link-container--active');
        $navItems.find('.active').parent().addClass('nav-link-container--active');

        initContent();
    };

    windowResize();
    $window.on('resize', function () {
        windowResize();
    });
    // $window.on('scroll', function (e) {
        // if (!ismobile) {
        //     animateMenue(e);
        // }
        // fadeContent();
    // });

    initContent();

    if (ismobile) {
        // if (performance.navigation.type && performance.navigation.type == 1) {
        //     return;
        // }
        // setTimeout(function() {
        //     var $link = $('.nav-link.active').find('>a');
        //     goTo($link);
        // }, 1000);
    }

});

function initContent() {
    // if(window.IntersectionObserver) {
    //     intersectionObserver = new IntersectionObserver(function(event) {
    //         event.forEach(function(entry) {
    //             if(entry.isIntersecting === true) {
    //                 entry.target.classList.add('fadeContentIn');
    //                 intersectionObserver.unobserve(entry.target);
    //             }
    //         });
    //     });
    // }

    // initFadeTeaser();
    // initTeaserSize();
    // initFadeContent();
    // if(ismobile) {
    //     initMobileFunctions();
    // }
    //
    // if(!intersectionObserver) {
    //     $window.off('scroll').on('scroll', function (e) {
    //         fadeContent();
    //     });
    // }
    // fadeContent();
}

function overrideLoader() {
    loaderOverridden = true;
    onepager.commonMethod._getPage = onepager.commonMethod.getPage;
    onepager.commonMethod._setWait = onepager.commonMethod.setWait;
    onepager.commonMethod.getPage = function() {};
    onepager.commonMethod.setWait = function() {};
    $(onepager.variables.listener).off('click').on('click', function(e) {
        e.preventDefault();
        e.stopPropagation();
        goTo($(e.currentTarget));
    })
}

function revertOverrideLoader() {
    loaderOverridden = false;
    onepager.commonMethod.getPage = onepager.commonMethod._getPage;
    onepager.commonMethod.setWait = onepager.commonMethod._setWait;
    $(onepager.variables.listener).off('click');
}
function goTo($link) {
    var markerName = $link.attr('data-name');
    if(!markerName || markerName == '') {
        markerName = $link.attr('title');
    }
    var marker = parseInt($('a[name="'+markerName+'"]').offset().top);
    window.scrollTo(0, marker);
}


function lazyLoad() {
    // fitNavspaltenHeaders();
    $(window).trigger('scroll');
}

function initFadeContent() {
    fadeableContent = $('.main__content__ajax').find('>.container, >.container-fluid').find('>*').not('.no-fade');
    fadeableFooter = $('#footer').find('>div').not('.no-fade');

    if(!intersectionObserver) {
        $.each(fadeableContent, function (i, v) {
            $(v).data().top = $(v).offset().top;
        });

        $.each(fadeableFooter, function (i, v) {
            $(v).data().top = $(v).offset().top;
        });
    } else {
        $.each(fadeableContent, function(i, v) {
            intersectionObserver.observe(v);
        });
        $.each(fadeableFooter, function(i, v) {
            intersectionObserver.observe(v);
        });
    }
}

function fadeContent() {
    var offset = $window.scrollTop() + $window.height() + 200;
    $.each(fadeableContent, function (i, v) {
        if ($(v).data().top < offset) {
            $(v).addClass('fadeContentIn');
        }
    });
    if (fadeableFooter.data().top < offset) {
        fadeableFooter.addClass('fadeContentIn');
    }
}

function windowResize() {
    if (md.mobile()) {
        if (!ismobile || ismobile === '23') {
            ismobile = true;
            if(!loaderOverridden) {
                overrideLoader();
            }
        }
    } else {
        if (ismobile || ismobile === '23') {
            ismobile = false;
            if(loaderOverridden) {
                revertOverrideLoader();
            }
        }
    }
}

function animateMenue(e) {
    if (ismobile) return;
    var scrollhoehe = e.currentTarget.scrollY;

    var headerOffset = scrollhoehe / 4;

    var m = scrollhoehe / 6;
    var bannerHeight = scrollhoehe / 3;
    if(bannerHeight > 500) bannerHeight = 500;
    // var scale = 1*(scrollhoehe/30);
    // if(scale < 1) scale = 1;
    // if(scale > 10) scale = 10;

    if(headerOffset > menueMaxScrollHoehe) headerOffset = menueMaxScrollHoehe;
    $header.css('transform','translateY(-'+headerOffset+'px)');


    var logoHeight = 130 - headerOffset;

    if(logoHeight < 50) logoHeight = 50;
    if(logoHeight > 130) logoHeight = 130;

    $logo.css('height', logoHeight + 'px');
    $realLogo.css('height', logoHeight + 'px');

    var fbIconTop = 15 + headerOffset;
    if(fbIconTop > 70) fbIconTop = 70;
    $fbIcon.css('top', fbIconTop + 'px');

    var navbarTranslateTop = scrollhoehe / 8;
    if(navbarTranslateTop > 23) navbarTranslateTop = 23;
    $navbar.css('transform', 'translateY(-50%) translateY(-'+navbarTranslateTop+'px)');

    if($teaser.hasClass('hideTeaser') === false) {
        m = m * teaserSliderScrollFaktor;
        $teaser.css('transform','translateY('+m+'px)');
        $teaser.find('.flex-control-nav').css('transform','translateY(-'+m+'px)');
        $teasertextContainer.css('transform', 'translateX(-50%) translateY(-'+m+'px)');
        // $banner.css('transform','scaleY('+scale+')');
        $banner.css('padding-top',(100+bannerHeight)+'px');
    }
}

function initKontaktformular() {
    var $frmContact = $('#frmContact');
    var $submitBtn = $('#submitBtn');
    $frmContact.submit(function () {
        var l = Ladda.create(document.querySelector('#submitBtn'));
        l.start();
        $.ajax({
            type: 'POST',
            url: window.location.href,
            data: $frmContact.serialize(),
        }).done().then(function (msg) {
            l.stop();
            msg = JSON.parse(msg);
            var tmp = $submitBtn.parent().html();
            $submitBtn.parent().html('<div class="alert alert-' + msg.status + ' text-left fade in">' + msg.text + '</div>');
            if (msg.status === 'success')
                $frmContact[0].reset();
            setTimeout(function () {
                $('.alert').parent().fadeOut(300, function () {
                    $('.alert').parent().html(tmp);
                    $submitBtn.parent().fadeIn(300);
                });
            }, 1500);
        });

        return false;
    });
}

function initMobileFunctions() {
    $('.mobilemenu__button').off('click').on('click', function () {
        $('.mobilemenu').addClass('open');
    });
    $('.mobilemenu__button-close, .mobilemenu').off('click').on('click', function () {
        $('.mobilemenu').removeClass('open');
        $('.mobilemenu__button-close').css('position', 'absolute');
    });
    $('.mobilemenu').on('transitionend', function (e) {
        if(parseInt(getComputedStyle(e.target).right) == 0) {
            $(e.target).find('.mobilemenu__button-close').css('position', 'fixed');
        } else {
            $('.mobilemenu').scrollTop(0);
        }
    });
}

function initFadeTeaser() {
    $teasertext.addClass('show');
    $teasertext.find('span').addClass('show');
}

var disabledLink = function(e) {
    e.preventDefault();
    e.stopPropagation();
};
